<template>
  <div class="admin">
    <LeftSideNav>
      <template v-slot:sideNavigation>
        <SideBar title="Admin Panel" base-loc="admin" :elements="pages" mobile-icon="menu"  >

        </SideBar>
      </template>
      <template v-slot:mainContent>
        <router-view v-slot="{Component}">
          <component :is="Component"></component>
        </router-view>
      </template>
    </LeftSideNav>

  </div>
</template>

<script>
import LeftSideNav from "@/layouts/LeftSideNav";
import SideBar from "@/components/Navigation/Sidebar";
export default {
  name: "Admin",
  components: {SideBar, LeftSideNav},
  data(){
    return{

      pages:[
        {
          id:'events',
          title:'Events',
          icon:'event_available'
        },
        {
          id:'games',
          title:'Spiele',
          icon:'videogame_asset'
        },
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
@import "./src/styles/themes";
  .admin{
    padding-top:80px;
    box-sizing: border-box;
    position: relative;
    @include theme {
      background-color: theme-get('background-color-2');
    }
    min-height: 100%;
    height: 100%;
  }
  #nav{
    z-index: 99;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 80px;
    @include theme{
      background-color: theme-get('background-color-2');

    }
    color: #FFFFFF;
    a{
      height: 100%;
      color: #FFFFFF;

      &.selected{
        font-weight: 600;
        @include theme {
          background-color: theme-get('navbar-selection-background');
          color: theme-get('primary-color')
        }
      }
    }
    .navItem{
      display: flex;
      justify-content: center;
      align-items: center;
      padding:16px;
      height: 100%;

      &:hover{
        @include theme{
          background-color: theme-get('navbar-selection-background');
        }

      }
    }
  }
</style>
