<template>
  <div>
    <div class="sideNav">
      <slot name="sideNavigation">
      </slot>
    </div>
    <div id="contentContainer">
      <slot name="mainContent">
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "LeftSideNav"
}
</script>

<style scoped lang="scss">
@import "../styles/themes.scss";
  #contentContainer{
    transition: all 0.25s ease;
    box-sizing: border-box;
    margin-left: 352px;
    gap: 16px;

    width: calc(100% - 352px);
    height: 100%;
    min-height: 100%;
    flex-direction: column;
    display: flex;
    @include theme {
      background-color: theme-get('background-color-2');
    }
  }
  .sideNav{

  }
  @media screen and (max-width: 1400px) {
    #contentContainer {
      width: 100%;
      margin: 0;
    }
  }
</style>
